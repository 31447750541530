import { memo } from 'react';

import type { IHeadingProps } from './interfaces';
import * as S from './styles';

function Heading({
  children,
  level = 1,
  italic = false,
  bold = false,
  as,
}: IHeadingProps) {
  return (
    <S.Heading level={level} italic={italic} bold={bold} as={as ?? `h${level}`}>
      {children}
    </S.Heading>
  );
}

export default memo(Heading);
