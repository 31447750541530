import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

type ISwitchProps = {
  isOn: boolean;
};

export const Container = styled.div``;

export const Handle = styled(motion.div)`
  ${({ theme }) => css`
    width: 1.6rem;
    height: 1.6rem;
    box-shadow: 0 0 0.025rem 0.025rem rgba(0, 0, 0, 0.2);
    background-color: ${theme.colors.text};
    border-radius: 1.6rem;

    transition: background-color 0.2s ease-in-out;
  `}
`;

export const Switch = styled.div<ISwitchProps>`
  ${({ theme, isOn }) => css`
    width: 3.4rem;
    height: 2rem;
    background: rgba(170, 170, 170, 0.2);
    display: flex;
    justify-content: ${isOn ? 'flex-end' : 'flex-start'};
    border-radius: 1.6rem;
    padding: 0.2rem;
    cursor: pointer;

    &:hover {
      ${Handle} {
        background-color: ${theme.colors.title};
      }
    }
  `}
`;
