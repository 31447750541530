import type * as React from 'react';

function Spain(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 750 500" {...props}>
      <title>Spain</title>
      <path fill="#c60b1e" d="M0 0H750V125H0z" />
      <path fill="#c60b1e" d="M0 375H750V500H0z" />
      <path fill="#ffc400" d="M0 125H750V375H0z" />
      <path fill="#ccc" d="M167 239H184V318H167z" />
      <path d="M167 318h17l3 12h-23l3-12z" fill="#c8b100" />
      <path fill="#005bbf" d="M160 330H191V343H160z" />
      <path d="M164 226h23l-3 13h-17l-3-13z" fill="#c8b100" />
      <path
        d="M164 219c0 1.516 1.55 2.873 4 3.79V226h15v-3.21c2.45-.917 4-2.274 4-3.79 0-2.762-5.149-5-11.5-5s-11.5 2.238-11.5 5z"
        fill="#ad1519"
      />
      <path
        d="M168 222c0-1.657 3.582-3 8-3s8 1.343 8 3-3.582 3-8 3-8-1.343-8-3z"
        fill="#c8b100"
      />
      <g>
        <path fill="#ccc" d="M316 239H333V318H316z" />
        <path d="M316 318h17l3 12h-23l3-12z" fill="#c8b100" />
        <path fill="#005bbf" d="M309 330H340V343H309z" />
        <path d="M313 226h23l-3 13h-17l-3-13z" fill="#c8b100" />
        <path
          d="M313 219c0 1.516 1.55 2.873 4 3.79V226h15v-3.21c2.45-.917 4-2.274 4-3.79 0-2.762-5.149-5-11.5-5s-11.5 2.238-11.5 5z"
          fill="#ad1519"
        />
        <path
          d="M317 222c0-1.657 3.582-3 8-3s8 1.343 8 3-3.582 3-8 3-8-1.343-8-3z"
          fill="#c8b100"
        />
      </g>
      <g>
        <path
          d="M199 198.5c0 4.83 8.221 9.147 20 11.99V224h63v-13.51c11.779-2.843 20-7.16 20-11.99 0-8.56-25.818-15.5-51.5-15.5s-51.5 6.94-51.5 15.5z"
          fill="#ad1519"
        />
        <path fill="#c8b100" d="M219 211H282V220H219z" />
        <path
          d="M245 175v21h10v-21l17 2v21h6v-18l19 6v14h6v-17h-6l-19-6v-3h-6l-17-2h-10l-17 2h-6v3l-19 6h-6v17h6v-14l19-6v18h6v-21l17-2z"
          fill="#c8b100"
        />
        <path d="M245 167a5 5 0 1110 0 5 5 0 01-10 0z" fill="#005bbf" />
      </g>
      <path fill="#ad1519" d="M156 258H200V268H156z" />
      <path fill="#ad1519" d="M299.6667 256.9167H343.6667V266.9167H299.6667z" />
      <path
        d="M157 294l33-18v8l-33 18v-8zM343 294l-33-18v8l33 18v-8z"
        fill="#ad1519"
      />
      <g>
        <path fill="#ad1519" d="M200 226H250V281H200z" />
        <path fill="#ccc" d="M250 226H300V281H250z" />
        <path fill="#ccc" d="M225 316H275V339H225z" />
        <path d="M200 318l25 21 25-21v-37h-50v37z" fill="#c8b100" />
        <path d="M250 318l25 21 25-21v-37h-50v37z" fill="#ad1519" />
        <path
          d="M239 281c0-7.732 5.149-14 11.5-14s11.5 6.268 11.5 14-5.149 14-11.5 14-11.5-6.268-11.5-14z"
          fill="#005bbf"
        />
      </g>
    </svg>
  );
}

export default Spain;
