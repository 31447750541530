import { Button, Tooltip } from '@radix-ui/themes';
import { Mail } from 'lucide-react';
import { useRouter } from 'next/router';
import { memo } from 'react';

import { Heading, SocialIcon, Text } from '@/components';
import { externalLinks } from '@/constants/externalLinks';

import * as S from './styles';

function Contact() {
  const { push } = useRouter();

  return (
    <S.Container>
      <S.Content>
        <S.TitleWrapper>
          <Heading level={6}>Let&apos;s connect!</Heading>
        </S.TitleWrapper>

        <S.TextWrapper>
          <Text size="small">
            If you&apos;d like to chat about building product, development,
            consulting or design do get in touch at{' '}
            <Tooltip content="Send email">
              <a href={externalLinks.mail_to} target="_blank" rel="noreferrer">
                hi@vinniciusgomes.dev
              </a>
            </Tooltip>{' '}
            or reach out to me on LinkedIn{' '}
            <Tooltip content="Open my LinkedIn">
              <a href={externalLinks.linkedin} target="_blank" rel="noreferrer">
                @vinniciusgomes
              </a>
            </Tooltip>
            .
          </Text>
        </S.TextWrapper>
        <S.ButtonsWrapper>
          <Button
            radius="full"
            variant="surface"
            color="indigo"
            onClick={() => push('/contact')}
          >
            <Mail size={14} /> Drop an email
          </Button>
          <Button
            radius="full"
            variant="surface"
            onClick={() => push(externalLinks.twitter)}
          >
            <SocialIcon icon="x" width={14} /> Send an DM
          </Button>
        </S.ButtonsWrapper>
      </S.Content>
    </S.Container>
  );
}

export default memo(Contact);
