import { memo } from 'react';

import type { ITextProps } from './interfaces';
import * as S from './styles';

function Text({
  children,
  fontFamily = 'primary',
  fontWeight = 'normal',
  color,
  italic = false,
  size = 'medium',
  ...props
}: ITextProps) {
  return (
    <S.Text
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      italic={italic}
      size={size}
      color={color}
      {...props}
    >
      {children}
    </S.Text>
  );
}

export default memo(Text);
