/* eslint-disable @typescript-eslint/no-explicit-any */
import { KBarPortal, KBarProvider, KBarResults, useDeepMatches } from 'kbar';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { forwardRef, useCallback, useRef } from 'react';

import aboutIcon from '@/assets/animations/icons/about.json';
import articlesIcon from '@/assets/animations/icons/articles.json';
import bookIcon from '@/assets/animations/icons/book.json';
import userIcon from '@/assets/animations/icons/camera.json';
import chatIcon from '@/assets/animations/icons/chat.json';
import clipboardIcon from '@/assets/animations/icons/clipboard.json';
import codeIcon from '@/assets/animations/icons/code.json';
import coinIcon from '@/assets/animations/icons/coin.json';
import emailIcon from '@/assets/animations/icons/email.json';
import homeIcon from '@/assets/animations/icons/home.json';
import lampIcon from '@/assets/animations/icons/lamp.json';
import linkIcon from '@/assets/animations/icons/link.json';
import terminalIcon from '@/assets/animations/icons/terminal.json';
import { externalLinks } from '@/constants/externalLinks';

import * as S from './styles';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export default function CommandBar(props: any) {
  const linkRef = useRef<any>(null);
  const emailRef = useRef<any>(null);
  const homeRef = useRef<any>(null);
  const aboutRef = useRef<any>(null);
  const articlesRef = useRef<any>(null);
  const projectsRef = useRef<any>(null);
  const startupsRef = useRef<any>(null);
  const companiesRef = useRef<any>(null);
  const stackRef = useRef<any>(null);
  const resumeRef = useRef<any>(null);
  const messageRef = useRef<any>(null);
  const bookLibraryRef = useRef<any>(null);
  const photographsRef = useRef<any>(null);
  const posthog = usePostHog();
  const router = useRouter();

  const sendWhatsappMessage = useCallback(() => {
    posthog.capture('kbar:send_whatsapp_message');

    window.open(externalLinks.whatsapp_link, '_blank');
  }, [posthog]);

  const iconSize = { width: 24, height: 24 };

  const handleNavigate = (url: string) => {
    posthog.capture('kbar:navigate', {
      navigate_to: url,
    });

    router.push(url);
  };

  const actions = [
    {
      id: 'links',
      name: 'My links',
      shortcut: ['l'],
      keywords: 'my-links',
      section: 'General',
      perform: () => handleNavigate('/links'),
      icon: (
        <Lottie
          lottieRef={linkRef}
          style={iconSize}
          animationData={linkIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'email',
      name: 'Send email',
      shortcut: ['e'],
      keywords: 'send-email',
      section: 'General',
      perform: () => handleNavigate('/contact'),
      icon: (
        <Lottie
          lottieRef={emailRef}
          style={iconSize}
          animationData={emailIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'message',
      name: 'Send message',
      shortcut: ['m'],
      keywords: 'send-message',
      section: 'General',
      perform: sendWhatsappMessage,
      icon: (
        <Lottie
          lottieRef={messageRef}
          style={iconSize}
          animationData={chatIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'home',
      name: 'Home',
      shortcut: ['g', 'h'],
      keywords: 'go-home',
      section: 'Go To',
      perform: () => handleNavigate('/'),
      icon: (
        <Lottie
          lottieRef={homeRef}
          style={iconSize}
          animationData={homeIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'articles',
      name: 'Articles',
      shortcut: ['g', 'b'],
      keywords: 'go-articles',
      section: 'Go To',
      perform: () => handleNavigate('/articles'),
      icon: (
        <Lottie
          lottieRef={articlesRef}
          style={iconSize}
          animationData={articlesIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'about',
      name: 'About',
      shortcut: ['g', 'a'],
      keywords: 'go-about',
      section: 'Go To',
      perform: () => handleNavigate('/about'),
      icon: (
        <Lottie
          lottieRef={aboutRef}
          style={iconSize}
          animationData={aboutIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'projects',
      name: 'Projects',
      shortcut: ['g', 'p'],
      keywords: 'go-projects',
      section: 'Go To',
      perform: () => handleNavigate('/projects'),
      icon: (
        <Lottie
          lottieRef={projectsRef}
          style={iconSize}
          animationData={codeIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'companies',
      name: 'Companies',
      shortcut: ['g', 'c'],
      keywords: 'go-companies',
      section: 'Go To',
      perform: () => handleNavigate('/companies'),
      icon: (
        <Lottie
          lottieRef={companiesRef}
          style={iconSize}
          animationData={coinIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'photographs',
      name: 'Photographs',
      shortcut: ['g', 'p'],
      keywords: 'go-photographs',
      section: 'Go To',
      perform: () => handleNavigate('/photographs'),
      icon: (
        <Lottie
          lottieRef={photographsRef}
          style={iconSize}
          animationData={userIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'reading',
      name: 'Reading',
      shortcut: ['g', 'l'],
      keywords: 'go-reading',
      section: 'Go To',
      perform: () => handleNavigate('/highlights'),
      icon: (
        <Lottie
          lottieRef={bookLibraryRef}
          style={iconSize}
          animationData={bookIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'stack',
      name: 'Stack',
      shortcut: ['g', 's'],
      keywords: 'go-stack',
      section: 'Go To',
      perform: () => handleNavigate('/stack'),
      icon: (
        <Lottie
          lottieRef={stackRef}
          style={iconSize}
          animationData={terminalIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'startups',
      name: 'Startups',
      shortcut: ['g', 's'],
      keywords: 'go-startups',
      section: 'Go To',
      perform: () => handleNavigate('/startups'),
      icon: (
        <Lottie
          lottieRef={startupsRef}
          style={iconSize}
          animationData={lampIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
    {
      id: 'resume',
      name: 'Resume',
      shortcut: ['g', 'r'],
      keywords: 'go-resume',
      section: 'Go To',
      perform: () => handleNavigate('/resume'),
      icon: (
        <Lottie
          lottieRef={resumeRef}
          style={iconSize}
          animationData={clipboardIcon}
          loop={false}
          autoplay={false}
        />
      ),
    },
  ];

  return (
    <KBarProvider actions={actions}>
      <KBarPortal>
        <S.Positioner>
          <S.Animator>
            <S.Search placeholder="Type a command or search…" />
            <RenderResults />
          </S.Animator>
        </S.Positioner>
      </KBarPortal>

      {props.children}
    </KBarProvider>
  );
}

function RenderResults() {
  const { results } = useDeepMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <S.GroupName>{item}</S.GroupName>
        ) : (
          <ResultItem action={item} active={active} />
        )
      }
    />
  );
}

const ResultItem = forwardRef(({ action, active }: any, ref: any) => {
  if (active) {
    action.icon?.props.lottieRef.current?.play();
  } else {
    action.icon?.props.lottieRef.current?.stop();
  }

  return (
    <S.Item
      ref={ref}
      active={active}
      onMouseEnter={() => action.icon?.props.lottieRef.current?.play()}
      onMouseLeave={() => action.icon?.props.lottieRef.current?.stop()}
    >
      <S.Action>
        {action.icon && action.icon}
        <S.ActionRow>
          <span>{action.name}</span>
        </S.ActionRow>
      </S.Action>
      {action.shortcut?.length ? (
        <S.Shortcut aria-hidden>
          {action.shortcut.map((shortcut: any) => (
            <S.Kbd key={shortcut}>{shortcut}</S.Kbd>
          ))}
        </S.Shortcut>
      ) : null}
    </S.Item>
  );
});

ResultItem.displayName = 'ResultItem';
