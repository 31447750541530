import { Router } from 'next/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

const postHogSettings = {
  api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
  api_key: process.env.NEXT_PUBLIC_POSTHOG_KEY as string,
};

function PostHogHocProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    posthog.init(postHogSettings.api_key, {
      api_host: postHogSettings.api_host,
      person_profiles: 'identified_only',
      loaded: posthog => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      },
    });

    const handleRouteChange = () => posthog?.capture('$pageview');

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

export { posthog, PostHogHocProvider };
