export const externalLinks = {
  linkedin: 'https://linkedin.com/in/vinniciusgomes',
  github: 'https://github.com/vinniciusgomes/',
  twitter: 'https://twitter.com/vinniciusgomees',
  instagram: 'https://www.instagram.com/vinniciusgomees/',
  medium: 'https://medium.com/@vinniciusgomes',
  stacko_verflow: 'https://stackoverflow.com/users/8071590/vinnicius-gomes',
  product_hunt: 'https://www.producthunt.com/@vinniciusgomes',
  mail_to: 'mailto:hi@vinniciusgomes.dev',
  my_library: 'https://my-library.vinniciusgomes.dev',
  resume_br: 'https://resume.vinniciusgomes.dev/',
  resume_en: 'https://vinniciusgomes.dev/pdf/en.pdf',
  first_web_site: 'https://pvpgladiadorcraft.wixsite.com/home',
  current_job_company: 'https://www.stone.com.br/',
  ton_link: 'https://www.ton.com.br/',
  liber_link: 'https://libercapital.com.br/',
  woney_web_site: 'https://woney.com.br',
  vifesolutions_web_site: 'https://vifesolutions.com/',
  uol_link: 'https://uol.com.br',
  iclubs_link: 'https://iclubs.tech',
  coren_link: 'https://coren.digital',
  zappts_link: 'https://zappts.com.br',
  romasofttecnologia_link: 'https://romasofttecnologia.com.br',
  gov_link: 'https://gov.br',
  unisal_link: 'https://unisal.br',
  rocketseat_link: 'https://rocketseat.com.br',
  stanford_link: 'https://stanford.edu',
  harvard_link: 'https://harvard.edu',
  whatsapp_link: 'https://api.whatsapp.com/send?phone=5512996326026',
  freelance_form_link: 'https://tally.so/r/3NXrWl',
  blog_subscribe_link: 'https://vinniciusgomes.medium.com/subscribe',
};
