import { GoogleAnalytics, sendGAEvent } from '@next/third-parties/google';
import { useReportWebVitals } from 'next/web-vitals';

const GA_MEASUREMENT_ID = 'G-K24ZV69MZR';

function GAHocProvider({ children }: { children: React.ReactNode }) {
  useReportWebVitals(metric => {
    if (typeof window !== 'undefined') {
      sendGAEvent({
        event: metric.name,
        value: Math.round(
          metric.name === 'CLS' ? metric.value * 1000 : metric.value,
        ),
        event_category: 'Web Vitals',
        event_label: metric.id,
        non_interaction: true,
      });
    }
  });

  return (
    <>
      <GoogleAnalytics gaId={GA_MEASUREMENT_ID} />
      {children}
    </>
  );
}

export { GAHocProvider };
