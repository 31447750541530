import styled, { css } from 'styled-components';

export const CardStyled = styled.div`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.small}) {
      > div {
        padding: 1.8rem;
        padding-top: 1.4rem !important;

        > div {
          gap: 0;
          p {
            margin-top: 1.2rem;
            margin-bottom: 0.4rem;
          }
        }
      }
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: 1rem;

    h3 {
      font-size: 2rem;
      margin: 0;
      padding: 0;
      color: ${theme.colors.title};
      line-height: 2.8rem;

      @media (max-width: ${theme.breakpoints.small}) {
        font-size: 1.8rem;
        margin: 0;
      }
    }

    span {
      color: ${theme.colors.text};
    }

    @media (max-width: ${theme.breakpoints.small}) {
      padding: 0 0.4rem;
    }
  `}
`;

export const ButtonsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-top: 1.6rem;
    margin-bottom: 0.6rem;

    button {
      @media (max-width: ${theme.breakpoints.small}) {
        font-size: 1.2rem;
        height: 2.4rem;
        padding: calc(var(--space-1) / 2) var(--space-2);
      }
    }
  `}
`;
