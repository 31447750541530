import styled, { css } from 'styled-components';

type IContainerProps = {
  hasMarginTop: boolean;
};

export const Container = styled.div<IContainerProps>`
  ${({ theme, hasMarginTop }) => css`
    display: grid;
    margin-top: ${hasMarginTop ? '4rem' : 0};
    gap: 6.4rem;

    @media (max-width: ${theme.breakpoints.large}) {
      gap: 4.8rem;
      margin-top: ${hasMarginTop ? '3.2rem' : 0};
    }
  `}
`;
