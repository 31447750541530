import { differenceInYears, parse } from 'date-fns';
import { useEffect, useState } from 'react';

export type AgeCalculatorData = {
  dateOfBirth: string;
};

const useAgeCalculator = ({ dateOfBirth }: AgeCalculatorData) => {
  const [age, setAge] = useState<number | null>(null);

  useEffect(() => {
    if (dateOfBirth) {
      const parsedDateOfBirth = parse(dateOfBirth, 'yyyy-MM-dd', new Date());
      const currentDate = new Date();
      const calculatedAge = differenceInYears(currentDate, parsedDateOfBirth);
      setAge(calculatedAge);
    }
  }, [dateOfBirth]);

  return { age };
};

export default useAgeCalculator;
