import { createContext, useContext } from 'react';
import { useTheme as useThemeStyledComponents } from 'styled-components';

import { THEMES_KEYS, type ThemesKeys } from '@/styles/themes';

import type { ThemeContextData, ThemeProviderProps } from './interfaces';

export const ThemeContext = createContext<ThemeContextData | null>(null);

function ThemeProvider({ children, updateTheme }: ThemeProviderProps) {
  const theme = useThemeStyledComponents();

  const toggleTheme = () => {
    if (theme.title === THEMES_KEYS.DARK_THEME) {
      updateTheme(THEMES_KEYS.LIGHT_THEME);
    } else {
      updateTheme(THEMES_KEYS.DARK_THEME);
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        updateTheme,
        toggleTheme,
        keyTheme: theme.title as ThemesKeys,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

function useTheme(): ThemeContextData {
  const context = useContext(ThemeContext);

  if (!context)
    throw new Error('useTheme must be used within an ThemeProvider');

  return context;
}

export { ThemeProvider, useTheme };
