import { memo } from 'react';

import type { IButtonProps } from './interfaces';
import * as S from './styles';

function Button({ children, icon, ...props }: IButtonProps) {
  return (
    <S.Wrapper {...props}>
      {icon && <S.IconWrapper>{icon}</S.IconWrapper>}
      <span>{children}</span>
    </S.Wrapper>
  );
}

export default memo(Button);
