import { Badge as RadixUIBadge } from '@radix-ui/themes';
import { memo } from 'react';

import type { IBadgeProps } from './interfaces';
import * as S from './styles';

const Badge = ({ children, ...rest }: IBadgeProps) => {
  return (
    <S.Container>
      <RadixUIBadge {...rest}>{children}</RadixUIBadge>
    </S.Container>
  );
};

export default memo(Badge);
