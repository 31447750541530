import { memo } from 'react';

import * as Icon from './icons';
import type { SocialIconProps } from './interfaces';

function SocialIcon({ icon, ...rest }: SocialIconProps) {
  const IconComponent = Icon[icon];

  if (!IconComponent) {
    return null;
  }

  return <IconComponent {...rest} />;
}

export default memo(SocialIcon);
