import { useEffect, useState } from 'react';

import * as S from './styles';

const ScrollIndicator = () => {
  const [progressWidth, setProgressWidth] = useState(0);
  useEffect(() => {
    window.onscroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      setProgressWidth(scrolled);
    };
  }, []);

  return (
    <S.ProgressContainer>
      <S.ProgressBar width={progressWidth} />
    </S.ProgressContainer>
  );
};

export default ScrollIndicator;
