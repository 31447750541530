import { Tooltip } from '@radix-ui/themes';
import { usePostHog } from 'posthog-js/react';
import { memo } from 'react';

import { MoonIcon, SunIcon } from '@/assets/icons';
import { useTheme } from '@/contexts';

import type { IToggleThemeButtonProps } from './interfaces';
import * as S from './styles';

const ToggleThemeButton = ({
  selectedTheme,
  toggleTheme,
}: IToggleThemeButtonProps) => {
  const { theme } = useTheme();
  const posthog = usePostHog();

  return (
    <>
      {selectedTheme === 'dark' ? (
        <Tooltip content="Switch to light mode">
          <S.Button
            onClick={() => {
              posthog.capture('header:toggle_theme', {
                theme: 'light',
              });
              toggleTheme();
            }}
            aria-label="Light mode"
          >
            <SunIcon color={theme.colors.title} />
          </S.Button>
        </Tooltip>
      ) : (
        <Tooltip content="Switch to dark mode">
          <S.Button
            onClick={() => {
              posthog.capture('header:toggle_theme', {
                theme: 'dark',
              });
              toggleTheme();
            }}
            aria-label="Dark mode"
          >
            <MoonIcon color={theme.colors.title} />
          </S.Button>
        </Tooltip>
      )}
    </>
  );
};

export default memo(ToggleThemeButton);
