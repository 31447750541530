import { type MouseEvent, type ReactNode, useState } from 'react';

import * as S from './styles';

function Card({ children }: { children: ReactNode }) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event: MouseEvent) => {
    const { left, top } = event.currentTarget.getBoundingClientRect();
    setMousePosition({ x: event.clientX - left, y: event.clientY - top });
  };

  const handleMouseLeave = () => {
    setMousePosition({ x: 0, y: 0 });
  };

  return (
    <S.Container onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      {children}

      <S.RevealEffect
        style={{
          background: `radial-gradient(200px circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(255, 255, 255, 0.06), transparent 80%)`,
          opacity: mousePosition.x === 0 && mousePosition.y === 0 ? 0 : 1,
        }}
      />
    </S.Container>
  );
}

export default Card;
