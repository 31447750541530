import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    padding: 0.4rem 1.2rem;
    background-color: ${theme.colors.cardColor};
    width: max-content;
    border-radius: 1.6rem;

    cursor: pointer;

    svg {
      height: 1.6rem;

      transition: margin-left 0.2s;
    }

    transition: background-color 0.2s;

    &:hover {
      background-color: ${darken(0.04, theme.colors.cardColor)};

      svg {
        margin-left: 0.4rem;
      }
    }
  `}
`;

export const BadgeWrapper = styled.div`
  margin-top: -0.1rem;
  margin-bottom: 0.1rem;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

export const Text = styled.span`
  font-size: 1.4rem;
`;
