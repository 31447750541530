import type * as React from 'react';

export function productHunt(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <title>product hunt</title>
      <path d="M12.001 22c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm1.334-8a1.5 1.5 0 000-3H10.5v3h2.834zm0-5a3.5 3.5 0 110 7H10.5v3h-2V7h4.834z" />
    </svg>
  );
}

export function linkedIn(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <title>linkedin</title>
      <path d="M12.001 9.55c.917-.937 2.111-1.55 3.5-1.55a5.5 5.5 0 015.5 5.5V21h-2v-7.5a3.5 3.5 0 10-7 0V21h-2V8.5h2v1.05zm-7-3.05a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-1 2h2V21h-2V8.5z" />
    </svg>
  );
}

export function gitHub(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <title>github</title>
      <path d="M5.884 18.653c-.3-.2-.558-.455-.86-.816a50.59 50.59 0 01-.466-.579c-.463-.575-.755-.841-1.056-.95a1 1 0 11.675-1.882c.752.27 1.261.735 1.947 1.588-.094-.117.34.427.433.539.19.227.33.365.44.438.204.137.588.196 1.15.14.024-.382.094-.753.202-1.095-2.968-.726-4.648-2.64-4.648-6.396 0-1.24.37-2.356 1.058-3.292-.218-.894-.185-1.975.302-3.192a1 1 0 01.63-.582c.081-.024.127-.035.208-.047.803-.124 1.937.17 3.415 1.096a11.73 11.73 0 012.687-.308c.912 0 1.819.104 2.684.308 1.477-.933 2.614-1.227 3.422-1.096.085.013.158.03.218.05a1 1 0 01.616.58c.487 1.216.52 2.296.302 3.19.691.936 1.058 2.045 1.058 3.293 0 3.757-1.674 5.665-4.642 6.392.125.415.19.878.19 1.38 0 .665-.002 1.299-.007 2.01 0 .19-.002.394-.005.706a1 1 0 01-.018 1.958c-1.14.227-1.984-.532-1.984-1.525l.002-.447.005-.705c.005-.707.008-1.337.008-1.997 0-.697-.184-1.152-.426-1.361-.661-.57-.326-1.654.541-1.751 2.966-.333 4.336-1.482 4.336-4.66 0-.955-.312-1.744-.913-2.404A1 1 0 0117.2 6.19c.166-.414.236-.957.095-1.614l-.01.003c-.491.139-1.11.44-1.858.949a1 1 0 01-.833.135 9.626 9.626 0 00-2.592-.349c-.89 0-1.772.118-2.592.35a1 1 0 01-.829-.134c-.753-.507-1.374-.807-1.87-.947-.143.653-.072 1.194.093 1.607a1 1 0 01-.189 1.045c-.597.655-.913 1.458-.913 2.404 0 3.172 1.371 4.328 4.322 4.66.865.097 1.202 1.177.545 1.748-.193.168-.43.732-.43 1.364v3.15c0 .985-.834 1.725-1.96 1.528a1 1 0 01-.04-1.962v-.99c-.91.061-1.661-.088-2.254-.485z" />
    </svg>
  );
}

export function x(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <title>x</title>
      <path d="M8 2H1l8.26 11.014L1.45 22H4.1l6.388-7.349L16 22h7l-8.608-11.478L21.8 2h-2.65l-5.986 6.886L8 2zm9 18L5 4h2l12 16h-2z" />
    </svg>
  );
}

export function instagram(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <title>instagram</title>
      <path d="M12.001 9a3 3 0 100 6 3 3 0 000-6zm0-2a5 5 0 110 10 5 5 0 010-10zm6.5-.25a1.25 1.25 0 01-2.5 0 1.25 1.25 0 012.5 0zM12.001 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 00-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.007 9.075 4 9.461 4 12c0 2.475.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333 1.104.052 1.49.058 4.029.058 2.475 0 2.878-.007 4.029-.058.782-.037 1.308-.142 1.797-.331.433-.169.748-.37 1.08-.703.337-.336.538-.649.704-1.08.19-.492.296-1.018.332-1.8.052-1.103.058-1.49.058-4.028 0-2.474-.007-2.878-.058-4.029-.037-.782-.143-1.31-.332-1.798a2.912 2.912 0 00-.703-1.08 2.884 2.884 0 00-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.926 4.006 14.54 4 12 4zm0-2c2.717 0 3.056.01 4.123.06 1.064.05 1.79.217 2.427.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 011.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.884 4.884 0 01-1.153 1.772 4.915 4.915 0 01-1.772 1.153c-.637.247-1.363.415-2.427.465-1.067.047-1.406.06-4.123.06-2.717 0-3.056-.01-4.123-.06-1.064-.05-1.789-.218-2.427-.465a4.89 4.89 0 01-1.772-1.153 4.905 4.905 0 01-1.153-1.772c-.248-.637-.415-1.363-.465-2.428-.048-1.066-.06-1.405-.06-4.122 0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 011.153-1.772 4.897 4.897 0 011.772-1.153c.637-.248 1.362-.415 2.427-.465C8.945 2.013 9.284 2 12.001 2z" />
    </svg>
  );
}

export function medium(props: React.ComponentProps<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...props}
    >
      <title>medium</title>
      <path d="M8 7a5 5 0 100 10A5 5 0 008 7zm0-2a6.993 6.993 0 015.804 3.086l.034-.069C14.343 7.007 15.227 6 16.5 6c1.181 0 2.027.866 2.547 1.798.038-.05.079-.1.12-.147.29-.325.737-.651 1.333-.651s1.044.326 1.332.65c.29.327.505.743.665 1.17.323.863.503 1.987.503 3.18s-.18 2.317-.503 3.18c-.16.427-.375.843-.665 1.17-.288.324-.736.65-1.332.65-.596 0-1.044-.326-1.332-.65a2.634 2.634 0 01-.121-.148C18.527 17.134 17.68 18 16.5 18c-1.273 0-2.157-1.006-2.662-2.017a7.273 7.273 0 01-.034-.069A7 7 0 118 5zm12 7c0 .913.08 1.933.5 2.764.42-.83.5-1.85.5-2.764 0-.913-.08-1.933-.5-2.764-.42.83-.5 1.85-.5 2.764zm-2 0c0-.76-.213-4-1.5-4S15 11.24 15 12c0 .76.213 4 1.5 4s1.5-3.24 1.5-4z" />
    </svg>
  );
}
