import styled, { css } from 'styled-components';

import type ITheme from '@/styles/themes/interfaces';

export type IHeadingProps = {
  bold?: boolean;
  italic?: boolean;
  level: 1 | 2 | 3 | 4 | 5 | 6;
};

const headingModifier = {
  level: {
    1: (theme: ITheme) => css`
      font-size: ${theme.font.sizes.heading01};
      line-height: ${theme.font.lineHeight.heading01};

      @media (max-width: ${theme.breakpoints.small}) {
        font-size: 3.2rem;
        line-height: 3.8rem;
      }
    `,
    2: (theme: ITheme) => css`
      font-size: ${theme.font.sizes.heading02};
      line-height: ${theme.font.lineHeight.heading02};

      @media (max-width: ${theme.breakpoints.small}) {
        font-size: 2.8rem;
        line-height: 3rem;
      }
    `,
    3: (theme: ITheme) => css`
      font-size: ${theme.font.sizes.heading03};
      line-height: ${theme.font.lineHeight.heading03};

      @media (max-width: ${theme.breakpoints.small}) {
        font-size: 2.6rem;
        line-height: 2.8rem;
      }
    `,
    4: (theme: ITheme) => css`
      font-size: ${theme.font.sizes.heading04};
      line-height: ${theme.font.lineHeight.heading04};
    `,
    5: (theme: ITheme) => css`
      font-size: ${theme.font.sizes.heading05};
      line-height: ${theme.font.lineHeight.heading05};
    `,
    6: (theme: ITheme) => css`
      font-size: ${theme.font.sizes.heading06};
      line-height: ${theme.font.lineHeight.heading06};

      @media (max-width: ${theme.breakpoints.small}) {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    `,
  },
  bold: (theme: ITheme) => css`
    font-weight: ${theme.font.weights.bold};
  `,
  italic: () => css`
    font-style: italic;
  `,
};

export const Heading = styled('h1')<IHeadingProps>`
  ${({ theme, level, italic, bold }) => css`
    font-weight: ${theme.font.weights.bold};
    font-style: normal;

    small {
      font-size: 1.2rem;
      font-weight: ${theme.font.weights.normal};
      color: ${theme.colors.text};
    }

    ${!!level && headingModifier.level[level](theme)}
    ${!!bold && headingModifier.bold(theme)}
    ${!!italic && headingModifier.italic()}
  `}
`;
