import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: max-content;
`;

export const Content = styled.footer`
  width: 100%;
  display: grid;
  gap: 2.4rem;
`;

export const TitleWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${theme.breakpoints.small}) {
      flex-direction: column;
      align-items: flex-start;
      gap: 2.4rem;
    }
  `}
`;

export const TextWrapper = styled.div`
  max-width: 53.2rem;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  max-width: max-content;
`;
