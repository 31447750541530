export const PAGES_PATH = [
  {
    label: 'Articles',
    path: '/articles',
  },
  {
    label: 'About',
    path: '/about',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'Companies',
    path: '/companies',
  },
  {
    label: 'Stack',
    path: '/stack',
  },
  {
    label: 'Reading',
    path: '/highlights',
  },
  {
    label: 'Photographs',
    path: '/photographs',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];
