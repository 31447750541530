import { Info } from 'lucide-react';
import { memo } from 'react';

import { Text } from '../';
import type { IInputProps } from './interfaces';
import * as S from './styles';

const Input = ({ label, errorMessage, ...rest }: IInputProps) => {
  return (
    <S.Container hasError={!!errorMessage}>
      <Text>{label}</Text>
      <input {...rest} />

      {errorMessage && (
        <S.ErrorWrapper>
          <Info size={14} />

          <Text size="small" color="red">
            {errorMessage}
          </Text>
        </S.ErrorWrapper>
      )}
    </S.Container>
  );
};

export default memo(Input);
