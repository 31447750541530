import { type ReactNode, useState } from 'react';

import themes, { THEMES_KEYS, type ThemesKeys } from '@/styles/themes';
import type ITheme from '@/styles/themes/interfaces';

interface IState {
  key: ThemesKeys;
  theme: ITheme;
}

export type ThemeContextData = {
  theme: ITheme;
  updateTheme(theme: ThemesKeys): void;
  toggleTheme(): void;
  keyTheme: ThemesKeys;
};

export type ThemeProviderProps = {
  children: ReactNode;
  updateTheme(theme: ThemesKeys): void;
};

const LOCAL_STORAGE_KEY = 'theme';
const DEFAULT_THEME = themes.DARK_THEME;
const DEFAULT_KEY = THEMES_KEYS.DARK_THEME;

const initialState: IState = {
  key: DEFAULT_KEY,
  theme: DEFAULT_THEME,
};

const useThemeHook = () => {
  const [state, setState] = useState<IState>(() => {
    // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
    let STORED_THEME;

    if (typeof window !== 'undefined') {
      STORED_THEME = localStorage.getItem(LOCAL_STORAGE_KEY);
    }

    if (STORED_THEME && STORED_THEME in themes) {
      return {
        key: STORED_THEME as ThemesKeys,
        theme: themes[STORED_THEME as ThemesKeys],
      };
    }

    return initialState as IState;
  });

  const updateTheme = (themeAction: ThemesKeys) => {
    if (themeAction in themes) {
      localStorage.setItem(LOCAL_STORAGE_KEY, themeAction);
      const selectedTheme = themes[themeAction];

      setState({
        key: themeAction,
        theme: selectedTheme,
      });
    }
  };

  return { theme: state.theme, updateTheme };
};

export default useThemeHook;
