/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PostHog } from 'posthog-js';

import type { IArticleModel } from '@/models';

export function sendOpenArticleEvent(data: IArticleModel, sender: PostHog) {
  sender.capture('article:click', {
    article: data,
  });
}
