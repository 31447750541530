import { Tooltip } from '@radix-ui/themes';
import { Clock } from 'lucide-react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { memo, useState } from 'react';

import { Badge, Heading, Text } from '@/components';
import { useTheme } from '@/contexts';
import { sendOpenArticleEvent } from '@/pages/articles/utils';

import type { IArticleProps } from './interfaces';
import * as S from './styles';

function Article({ data, isNew }: IArticleProps) {
  const [isHovered, setIsHovered] = useState(false);
  const { theme } = useTheme();
  const posthog = usePostHog();
  const router = useRouter();

  const handleOpenArticle = () => {
    sendOpenArticleEvent(data, posthog);

    if (data.link.includes('medium')) {
      return window.open(data.link, '_blank');
    }

    return router.push(data.link);
  };

  return (
    <Tooltip content="Open article">
      <S.Container
        onHoverStart={() => setIsHovered(true)}
        onHoverEnd={() => setIsHovered(false)}
        onClick={handleOpenArticle}
      >
        <S.ImageWrapper>
          <Image
            src={data.image}
            alt={data.title}
            width={624}
            height={416}
            priority
            quality={100}
          />
        </S.ImageWrapper>

        <S.ArticleInfo>
          <Heading level={5}>{data.title}</Heading>

          {isNew && (
            <S.NewWrapper>
              <Badge
                color="green"
                variant={theme.title === 'DARK_THEME' ? 'soft' : 'solid'}
              >
                New article
              </Badge>
            </S.NewWrapper>
          )}

          <Text>
            {data.preview.length > 300
              ? `${data.preview.slice(0, 300)}...`
              : `${data.preview}...`}
          </Text>

          <S.ReadTimeWrapper>
            <Clock size={14} />
            <Text size="small">{data.readTime} min read</Text>
          </S.ReadTimeWrapper>
        </S.ArticleInfo>

        {isHovered && (
          <S.ItemHover
            layoutId="article"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8 }}
            exit={{ opacity: 0 }}
          />
        )}
      </S.Container>
    </Tooltip>
  );
}

export default memo(Article);
