import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.footer`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    gap: 0.8rem;
    font-family: ${theme.font.family.IBM};

    p {
      font-size: 1.2rem;
    }

    img {
      margin: 0 auto;
    }
  `}
`;

export const SocialWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 1.6rem;

    @media (max-width: ${theme.breakpoints.small}) {
      margin-top: 3.2rem;
    }
  `}
`;

export const SocialItem = styled(motion.a)`
  ${({ theme }) => css`
    color: ${theme.colors.icon};
    transition: color 0.2s ease-in-out;
    cursor: pointer;

    svg {
      width: 2rem;
      height: 2rem;
    }

    &:hover {
      color: ${theme.colors.highlightColor};
    }

    @media (max-width: ${theme.breakpoints.small}) {
      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }
  `}
`;

export const CreatedIn = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 0.8rem;
    font-family: ${theme.font.family.IBM};
    font-size: 1.2rem;
  `}
`;
