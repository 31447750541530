import { ArrowRight } from 'lucide-react';
import { memo } from 'react';

import Badge from '@/components/Badge';
import { useTheme } from '@/contexts';

import type { NotificationProps } from './interfaces';
import * as S from './styles';

function Notification({ id, badgeText, onClick, text }: NotificationProps) {
  const { theme } = useTheme();

  return (
    <S.Container onClick={onClick} id={id}>
      <S.Content>
        <S.BadgeWrapper>
          <Badge
            color="green"
            variant={theme.title === 'DARK_THEME' ? 'soft' : 'solid'}
          >
            {badgeText}
          </Badge>
        </S.BadgeWrapper>
        <S.Text>{text}</S.Text>
        <ArrowRight />
      </S.Content>
    </S.Container>
  );
}

export default memo(Notification);
