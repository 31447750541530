import type { CommonTheme } from './interfaces';

const themeSettingsCommons: CommonTheme = {
  font: {
    family: {
      Inter: "'Inter', sans-serif",
      IBM: "'IBM Plex Mono', sans-serif",
      FiraCode: "'Fira Code', monospace",
    },
    weights: {
      thin: 100,
      extraLight: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
      black: 900,
    },
    sizes: {
      xxsmall: '1.2rem',
      xsmall: '1.4rem',
      small: '1.4rem',
      normal: '1.6rem',
      large: '1.8rem',
      heading06: '2rem',
      heading05: '2.4rem',
      heading04: '2.8rem',
      heading03: '4.8rem',
      heading02: '5.6rem',
      heading01: '6.4rem',
    },
    lineHeight: {
      xxsmall: '1.6rem',
      xsmall: '1.8rem',
      small: '2.1rem',
      normal: '2.4rem',
      large: '2.7rem',
      heading06: '2.8rem',
      heading05: '2.9rem',
      heading04: '3.4rem',
      heading03: '7.2rem',
      heading02: '6.4rem',
      heading01: '7.2rem',
    },
  },
  breakpoints: {
    xsmall: '400px',
    small: '576px',
    normal: '650px',
    medium: '768px',
    large: '1024px',
    xlarge: '1200px',
    xxlarge: '1400px',
    xxxlarge: '1700px',
  },
  transitions: {
    textHover: 'cubic-bezier(0.4, 0, 1, 1) 0.3s',
  },
} as const;

export default themeSettingsCommons;
